#Loading
{
	display:          flex;
	position:         fixed;
	height:           100vh;
	width:            100vw;
	background-color: #111111;
	align-items:      center;
	justify-content:  center;
	z-index:          999999;
}
#Loading img
{
	height:    30vh;
	animation: color 1s infinite;
}
@keyframes color
{
	0%   { filter: grayscale(0); }
	50%  { filter: grayscale(1); }
	100% { filter: grayscale(0); }
}
@keyframes pulse
{
	0%   { transform: scale(1);    }
	30%  { transform: scale(1);    }
	40%  { transform: scale(1.08); }
	50%  { transform: scale(1);    }
	60%  { transform: scale(1);    }
	70%  { transform: scale(1.05); }
	80%  { transform: scale(1);    }
	100% { transform: scale(1);    }
}
