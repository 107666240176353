body
{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code
{
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* rDai Explorer specifig */
body
{
	color:            #FFFFFF;
	background-color: #111111;
	overflow:         hidden;
}

.navbar
{
	width:            100vw;
	z-index:          1000000;
	border-bottom:    1px solid #222222;
}

.navbar .navLogo
{
	height:           20px;
	margin-bottom:    5px;
	margin-right:     10px;
}

.core
{
	padding-top:      58px;
	height:           100vh;
	color:            #4F4F4F;
	background-color: #FFFFFF;
}

.modal
{
	width:            30vw;
	height:           100vh;
	left:             unset;
	right:            0;
	top:              0;
	padding-top:      58px;
}
.modal .modal-dialog
{
	max-width:        unset;
	margin:           0;
}
.modal .modal-dialog .modal-content
{
	border-top:       1px solid;
	border-left:      1px solid;
	border-bottom:    1px solid;
	border-color:     #000000;
	border-radius:    0.25rem 0 0 0.25rem;
	background-color: rgba(0, 0, 0, 0.5);
}
.modal .modal-dialog .modal-content .modal-header
{
	border-color:     #000000;
}
